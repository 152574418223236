import {SERVER} from "../constants";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {adviceMapperGetOneResponse} from "../panels/advice/adviceMapper";
import {blogMapperGetOneResponse} from "../panels/blog/blogMapper";
import {blogCategoryMapperGetOneResponse} from "../panels/blogCategory/blogCategoryMapper";
import {fixConfigId} from "../panels/config/configMapper";
import {userMapperGetOneResponse} from "../panels/user/userMapper";
import {adminUsersMapperGetOneResponse} from "../panels/adminUsers/adminUsersMapper";
import {avatarMapperGetOneResponse} from "../panels/avatar/avatarMapper";
import {childWeekMapperGetOneResponse} from "../panels/childWeek/childWeekMapper";
import {taskMapperGetOneResponse} from '../panels/task/taskMapper'
import {planMapperGetOneResponse} from '../panels/plan/planMapper'

export function getOne(resource, params) {
  return HttpClient(`${SERVER}/${MAP_URL[resource]}/${params.id}`).then(({json}) => {
      if (resource === 'advice') {
        return adviceMapperGetOneResponse(json);
      } else if (resource === 'blogCategory') {
        return blogCategoryMapperGetOneResponse(json);
      } else if (resource === 'blog') {
        return blogMapperGetOneResponse(json);
      } else if (resource === 'plan') {
        return planMapperGetOneResponse(json);
      } else if (resource === 'task') {
        return taskMapperGetOneResponse(json);
      } else if (resource === 'config') {
        return fixConfigId(params, json);
      } else if (resource === 'user') {
        return userMapperGetOneResponse(params, json);
      } else if (resource === 'adminUsers') {
        return adminUsersMapperGetOneResponse(json);
      } else if (resource === "avatar") {
        return avatarMapperGetOneResponse(json);
      } else if (resource === "childWeek") {
        return childWeekMapperGetOneResponse(json);
      }

      return {data: {...json.result, id: json.result.objectId}};
    }
  );
}
