import React from "react";
import {
    Datagrid,
    List,
    ImageField,
    ImageInput,
    SimpleShowLayout,
    TextField,
    TextInput, DateField, BooleanInput, required, BooleanField, Filter,
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";

const AvatarFilter = (props) => (
    <Filter {...props}>
        <TextInput source="objectId"/>
        <TextInput source="name"/>
        <BooleanInput source="active"/>
    </Filter>
);

export const AvatarList = (props) => (
  <List {...props} filters={<AvatarFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
        <TextField source="name"/>
        <BooleanField source="active"/>
        <ImageField source="avatarUrl"/>
        <DateField showTime={true} source="createdAt"/>
        <DateField showTime={true} source="updatedAt"/>
    </Datagrid>
  </List>
);

export const AvatarEdit = (props) => (
  <EditView {...props}>
    <TextInput source="name" validate={required()}/>
    <BooleanInput source="active" validate={required()}/>
    <ImageInput source="avatar" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <TextInput source="avatarUrl" validate={required()}/>
  </EditView>
);

export const AvatarShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
        <TextField source="name"/>
        <BooleanField source="active"/>
        <ImageField source="avatarUrl"/>
        <DateField showTime={true} source="createdAt"/>
        <DateField showTime={true} source="updatedAt"/>
    </SimpleShowLayout>
  </ShowView>
);

export const AvatarCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="name" validate={required()}/>
    <BooleanInput label="active" defaultValue={true} source="active" validate={required()} />
    <ImageInput source="avatar" validate={required()} label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <TextInput source="avatarUrl"/>
  </CreateView>
);
