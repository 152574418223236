import React from "react";
import {SaveButton, Toolbar} from "react-admin";

export function CreateFormActions(props) {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="ra.action.save_and_show"
        redirect="show"
        submitOnEnter={true}
      />
      <SaveButton
        label="ra.action.save_and_add"
        redirect={false}
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  );
}

export function EditFormActions(props) {
  return (
    <Toolbar {...props}>
      <SaveButton redirect={false}/>
      <SaveButton
        label="ra.action.save_and_show"
        redirect="show"
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  );
}
