import React from "react";
import {
    Datagrid,
    List,
    ImageField,
    ImageInput,
    SimpleShowLayout,
    TextField,
    TextInput,
    DateField,
    required,
    Filter,
    NumberField,
    NumberInput,
    RichTextField,
    ArrayInput,
    SimpleFormIterator,
    SelectInput, ArrayField,
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import Emoji from "../../components/Emoji";
import {Markets, MarketsFilter} from "../../constants/choices";

const ChildWeekFilter = (props) => (
  <Filter {...props}>
    <TextInput source="objectId"/>
    <NumberInput source="weekNumber"/>
      <SelectInput
          label="Market"
          choices={MarketsFilter}
          optionText={"name"}
          optionValue={"value"}
          source={"markets"}
          alwaysOn
      />
  </Filter>
);

export const ChildWeekList = (props) => (
  <List {...props} filters={<ChildWeekFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <NumberField source="weekNumber"/>
      <TextField source="weight"/>
      <TextField source="height"/>
      <RichTextField source="description"/>
      <RichTextField source="additionalDescription"/>
      <ImageField source="imageUrl"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </Datagrid>
  </List>
);



export const ChildWeekShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <NumberField source="weekNumber"/>
      <TextField source="weight"/>
      <TextField source="height"/>
      <RichTextField source="description"/>
      <RichTextField source="additionalDescription"/>
      <ImageField source="imageUrl"/>
        <ArrayField source="adminMarkets">
            <Datagrid>
                <TextField source="markets" />
            </Datagrid>
        </ArrayField>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </SimpleShowLayout>
  </ShowView>
);

export const ChildWeekCreate = (props) => (
  <CreateView {...props}>
    <NumberInput source="weekNumber" validate={required()}/>
    <TextInput source="weight" validate={required()}/>
    <TextInput source="height" validate={required()}/>
    <Emoji source="description" label="Description" />
    <Emoji source="additionalDescription" label="Additional description"/>
    <ImageInput source="image" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
      <ArrayInput source="adminMarkets">
          <SimpleFormIterator>
              <SelectInput source="markets" choices={Markets} />
          </SimpleFormIterator>
      </ArrayInput>
  </CreateView>
);

export const ChildWeekEdit = (props) => (
  <EditView {...props}>
    <NumberInput source="weekNumber" validate={required()}/>
    <TextInput source="weight" validate={required()}/>
    <TextInput source="height" validate={required()}/>
    <Emoji source="description" label="Description" />
    <Emoji source="additionalDescription" label="Additional description"/>
    <ImageInput source="image" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
      <ArrayInput source="adminMarkets">
          <SimpleFormIterator>
              <SelectInput source="markets" choices={Markets} />
          </SimpleFormIterator>
      </ArrayInput>
  </EditView>
);
