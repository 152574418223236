import {AUTH_TYPES} from "../auth/authConstants";
import {ROLES} from "../constants";

export function arrayUniqueById(array) {
  const newArray = [];
  array.forEach(item => {
    const existedItems = newArray.filter(innerItem => item.id === innerItem.id);
    if (existedItems.length === 0) {
      newArray.push(item);
    }
  });
  return newArray;
}

export function hasCurrentUserPermissionByName(permissionKey) {
  const role = localStorage.getItem(AUTH_TYPES.ROLE);
  if (!role) {
    return false;
  }
  if (role === ROLES.SUPER_ADMIN) {
    return true;
  }
  return role === permissionKey;
}

