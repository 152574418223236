import React from "react";
import {
  Datagrid,
  List, NumberInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import {Roles} from "../../constants/choices"

export const AdminUserList = (props) => (
  <List {...props} perPage={20}>
    <Datagrid rowClick="show">
      <TextField source="name"/>
      <TextField source="phoneNumber"/>
      <TextField source="role"/>
    </Datagrid>
  </List>
);

export const AdminUserEdit = (props) => (
  <EditView {...props}>
    <TextInput disabled={true} source="name"/>
    <NumberInput disabled={true} source="phoneNumber"/>
    <SelectInput source="role" choices={Roles}/>
  </EditView>
);

export const AdminUserShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField disabled={true} source="name"/>
      <TextField disabled={true} source="phoneNumber"/>
      <TextField disabled={true} source="role"/>
    </SimpleShowLayout>
  </ShowView>
);

export const AdminUserCreate = (props) => (
  <CreateView {...props}>
    <SelectInput source="role" choices={Roles}/>
    <TextInput source="phoneNumber"/>
  </CreateView>
);
