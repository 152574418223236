import React from "react";
import {
    Datagrid,
    List,
    SimpleShowLayout,
    TextField,
    DateField,
    Filter,
    TextInput,
    BooleanInput,
    BooleanField,
    SelectInput,
    ImageField,
    ImageInput,
    UrlField,
} from "react-admin";
import ShowView from "../../components/ShowView";
import EditView from "../../components/EditView";
import { AutocompleteArrayInput } from "react-admin";
import {ChatRoles} from "../../constants/choices";
import {UserFilterRole} from "./userConstants";

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput label="ID" source="objectId" />
      <TextInput label="Name" source="name" />
    <TextInput label="Phone number" source="phoneNumber" />
    <TextInput label="Email" source="email" />
    <SelectInput label="Role" choices={UserFilterRole} optionText={"name"} optionValue={"value"} source={"role"} alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List {...props} filters={<UserFilter />} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="objectId" />
      <DateField showTime={true} source="registeredDate" />
      <DateField showTime={true} source="updatedAt" />
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <BooleanField source="signupCompleted" />
      <BooleanField source="isBlocked" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

export const UserShow = (props) => (
  <ShowView {...props} noEdit={false}>
    <SimpleShowLayout>
      <TextField source="objectId" />
      <DateField showTime={true} source="updatedAt" />
      <TextField source="phoneNumber" />
      <TextField source="name" />
      <BooleanField source="signupCompleted" />
      <BooleanField source="isBlocked" />
      <DateField showTime={true} source="registeredDate" />
      <TextField source="weight" />
      <DateField showTime={true} source="birthdate" />
      <TextField source="averagePeriodLength" />
      <TextField source="averageCycleLength" />
      <TextField source="averagePredictedCycleLength" />
      <TextField source="irregularityStartDay" />
      <TextField source="irregularityEndDay" />
      <TextField source="averageIrregularityCycleLength" />
      <TextField source="lastPeriodStartDate" />
      <TextField source="lastPeriodEndDate" />
      <TextField source="minimumCycleLength" />
      <TextField source="maximumCycleLength" />
      <TextField source="firstNextPeriodStartDate" />
      <TextField source="nearestPeriodOvulation" />
      <TextField source="numberOfPeriods" />
      <TextField source="description" />
      <ImageField source="avatarUrl" />
      <UrlField source="avatarUrl" />
      <TextField source="market" />
      <TextField source="role" />
      <TextField source="sex" />
      <TextField source="userGoal" />
      <TextField source="deviceInfo" />
    </SimpleShowLayout>
  </ShowView>
);

export const UserEdit = (props) => (
  <EditView {...props}>
    <TextField source="phoneNumber" />
    <TextInput source="name" />
    <AutocompleteArrayInput source="role" choices={ChatRoles} />
    <BooleanInput source="isBlocked" />
    <ImageInput source="files" label="Image" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <TextInput source="avatarUrl" />
  </EditView>
);
