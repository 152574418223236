import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleShowLayout,
  TextField,
  ReferenceInput,
  TextInput,
  required,
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import Emoji from "../../components/Emoji";
import {SymptomsFilter} from '../../constants/choices'

const TaskFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Plan" source="planId" reference="plan">
      <SelectInput optionText="title" alwaysOn/>
    </ReferenceInput>
    <SelectInput
      label="Symptoms"
      choices={SymptomsFilter()}
      optionText={"name"}
      optionValue={"value"}
      source={"symptoms"}
      alwaysOn
    />
  </Filter>
);

export const TaskList = (props) => (
  <List {...props} filters={<TaskFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ImageField source="icon"/>
      <TextField source="title"/>
      <ReferenceField link="show" label="Plan" source="planId" reference="plan" >
        <TextField source="title"/>
      </ReferenceField>
      <TextField source="order"/>
    </Datagrid>
  </List>
);

export const TaskEdit = (props) => (
  <EditView {...props} perPage={10}>
    <ReferenceInput label="Plan" validate={required()} source="planId" reference="plan">
      <SelectInput optionText="title" alwaysOn/>
    </ReferenceInput>
    <TextInput source="title" validate={required()}/>
    <NumberInput source="order" validate={required()}/>
    <Emoji source="description" label="Description" />
    <Emoji source="descriptionHt" label="Description for hormone therapy" />
    <NumberInput source="order"/>
    <ImageInput source="pictures"  label="Image" accept="image/*">
      <ImageField source="src" title="title"/>
    </ImageInput>
    <TextInput source="icon" validate={required()}/>
  </EditView>
);

export const TaskShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <ImageField source="icon"/>
      <TextField source="objectId"/>
      <TextField source="title"/>
      <TextField source="description"/>
      <TextField source="descriptionHt"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ReferenceField link="show" label="Plan" source="planId" reference="plan" >
        <TextField source="title"/>
      </ReferenceField>
      <TextField source="order"/>
    </SimpleShowLayout>
  </ShowView>
);

export const TaskCreate = (props) => (
  <CreateView {...props}>
    <ReferenceInput label="Plan" source="planId" validate={required()} reference="plan">
      <SelectInput optionText="title"  alwaysOn/>
    </ReferenceInput>
    <TextInput source="title" validate={required()}/>
    <Emoji source="description" label="Description" />
    <Emoji source="descriptionHt" label="Description for hormone therapy " />
    <NumberInput source="order"/>
    <ImageInput source="pictures" validate={required()} label="Image" accept="image/*">
      <ImageField source="src" validate={required()} title="title"/>
    </ImageInput>
  </CreateView>
);
