import React from "react";
import {
  Datagrid,
  List, NumberInput,
  ImageField,
  ImageInput,
  SimpleShowLayout,
  TextField,
  UrlField,
  ReferenceField,
  TextInput,
  ArrayField,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  DateField,
  SelectInput,
  Filter,
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import Emoji from '../../components/Emoji'
import {Symptoms, SymptomsFilter} from '../../constants/choices'

const PlanFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Symptoms"
      choices={SymptomsFilter()}
      optionText={"name"}
      optionValue={"value"}
      source={"symptoms"}
      alwaysOn
    />
  </Filter>
);

export const PlanList = (props) => (
  <List {...props} filters={<PlanFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <ImageField source="icon"/>
      <TextField source="title"/>
      <TextField source="duration" label="Duration (days)"/>
      <ArrayField label="Symptoms" source="adminSymptoms">
        <Datagrid>
          <TextField label="Symptom" source="symptoms" />
        </Datagrid>
      </ArrayField>
      <ReferenceField link="show" label="Previous plan" source="parentId" reference="plan" >
        <TextField source="title"/>
      </ReferenceField>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </Datagrid>
  </List>
);

export const PlanEdit = (props) => (
  <EditView {...props}>
    <ReferenceInput label="Previous plan" source="parentId" reference="plan">
      <SelectInput optionText="title" alwaysOn/>
    </ReferenceInput>
    <TextInput source="title"/>
    <Emoji source="description" label="Description" />
    <Emoji source="shortDescription" label="Short description" />
    <NumberInput source="duration" label="Duration (days)"/>
    <ArrayInput label="Symptoms" source="adminSymptoms">
      <SimpleFormIterator>
        <SelectInput source="symptoms" choices={Symptoms} />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="icon"/>
    <ImageInput source="pictures" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <TextInput source="referenceUrl"/>
  </EditView>
);

export const PlanShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <ReferenceField link="show" label="Previous plan" source="parentId" reference="plan" >
        <TextField source="title"/>
      </ReferenceField>
      <TextField source="objectId"/>
      <TextField source="title"/>
      <TextField source="description"/>
      <TextField source="shortDescription"/>
      <TextField source="duration" label="Duration (days)"/>
      <ArrayField label="Symptoms" source="adminSymptoms">
        <Datagrid>
          <TextField label="Symptom" source="symptoms" />
        </Datagrid>
      </ArrayField>
      <ImageField source="icon"/>
      <UrlField source="referenceUrl"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </SimpleShowLayout>
  </ShowView>
);

export const PlanCreate = (props) => (
  <CreateView {...props}>
    <ReferenceInput label="Previous plan" source="parentId" reference="plan">
      <SelectInput optionText="title"  alwaysOn/>
    </ReferenceInput>
    <TextInput source="title"/>
    <NumberInput label="Duration (days)" source="duration"/>
    <Emoji source="description" label="Description" />
    <Emoji source="shortDescription" label="Short description" />
    <ArrayInput label="Symptoms" source="adminSymptoms">
      <SimpleFormIterator>
        <SelectInput label="Symptom" source="symptoms" choices={Symptoms} />
      </SimpleFormIterator>
    </ArrayInput>
    <ImageInput source="pictures" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <TextInput source="referenceUrl"/>
  </CreateView>
);
