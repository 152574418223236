import {AdviceTypes} from "./adviceConstants";

export function adviceListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      type: getType(resource.type),
      adminSymptoms: resource.symptoms.map((item) => ({symptoms : item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateAdviceId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixCreateAdviceId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function adviceMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId, typeName: getType(json.result.type),
      adminSymptoms: json.result.symptoms.map((item) => ({symptoms : item})),
    },
  };
}


export function getType(type) {
  const foundType = AdviceTypes.find((item) => item.id === type);

  if (foundType) {
    return foundType.name;
  }
  return ""
}
