import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  RichTextField,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  ImageField,
  UrlField,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  BooleanField,
  BooleanInput,
  ArrayField,
} from "react-admin";
import CreateView from "../../components/CreateView";
import EditView from "../../components/EditView";
import ShowView from "../../components/ShowView";
import {AdviceFilterConditions, AdviceFilterTypes, AdviceTypes} from "./adviceConstants";
import Emoji from "../../components/Emoji";
import {Symptoms, SymptomsFilter} from "../../constants/choices";

const AdviceFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Type"
      choices={AdviceFilterTypes}
      optionText={"name"}
      optionValue={"value"}
      source={"type"}
      alwaysOn
    />
    <SelectInput
      label="Symptoms"
      choices={SymptomsFilter()}
      optionText={"name"}
      optionValue={"value"}
      source={"symptoms"}
      alwaysOn
    />
    <SelectInput
      label="Pin"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isPin"}
    />
    <SelectInput
      label="Ads"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isAdvertise"}
    />
    <SelectInput
      label="Hidden"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isHidden"}
    />
  </Filter>
);

export const AdviceList = (props) => (
  <List {...props} filters={<AdviceFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <TextField source="title"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <FunctionField
        label="Content"
        render={record => `${record.text.substr(0, 150)} `}
      />;
      <TextField source="urlTitle"/>
      <UrlField source="url"/>
      <ImageField source="bannerImage"/>
      <BooleanField source="isPin"/>
      <BooleanField source="isAdvertise"/>
      <BooleanField source="isHidden"/>
      <TextField source="type"/>
      <ArrayField label="Symptoms" source="adminSymptoms">
        <Datagrid>
          <TextField label="Symptom" source="symptoms" />
        </Datagrid>
      </ArrayField>
      <BooleanField source="displayOnHt"/>
    </Datagrid>
  </List>
);

export const AdviceCreate = (props) => {
  return (
    <CreateView {...props} perPage={10}>
      <TextInput source="title" validate={required()}/>
      <SelectInput source="type" validate={required()} choices={AdviceTypes}/>
      <TextInput source="urlTitle"/>
      <TextInput source="url"/>
      <Emoji label="Description" source="text" validate={required()}/>
      <BooleanInput label="Pin? All pinned advices will be display based on the type" source="isPin" defaultValue={false}/>
      <BooleanInput label="Ads? One randomg ads advice will be display based on the type" source="isAdvertise" defaultValue={false}/>
      <BooleanInput source="isHidden" defaultValue={false}/>
      <ImageInput source="pictures" label="Image" accept="image/*">
        <ImageField source="src" title="imageUrl"/>
      </ImageInput>
      <ArrayInput label="Symptoms" source="adminSymptoms">
        <SimpleFormIterator>
          <SelectInput label="Symptom" source="symptoms" choices={Symptoms} />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="displayOnHt" />
    </CreateView>
  );
}


export const AdviceEdit = (props) => {
  return (
    <EditView {...props}>
      <TextInput source="title" validate={required()}/>
      <SelectInput source="type" validate={required()} choices={AdviceTypes}/>
      <TextInput source="urlTitle"/>
      <TextInput source="url"/>
      <Emoji label="Description" source="text" validate={required()}/>
      <BooleanInput source="isPin" defaultValue={false}/>
      <BooleanInput source="isAdvertise" defaultValue={false}/>
      <BooleanInput source="isHidden" defaultValue={false}/>
      <ImageInput source="pictures" label="Image" accept="image/*">
        <ImageField source="src" title="imageUrl"/>
      </ImageInput>
      <TextInput source="bannerImage"/>
      <ArrayInput label="Symptoms" source="adminSymptoms">
        <SimpleFormIterator>
          <SelectInput source="symptoms" choices={Symptoms} />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="displayOnHt" />
    </EditView>
  );
}

export const AdviceShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
      <TextField source="title"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <RichTextField source="text"/>
      <BooleanField source="isPin"/>
      <BooleanField source="isAdvertise"/>
      <BooleanField source="isHidden"/>
      <TextField source="urlTitle"/>
      <UrlField source="url"/>
      <ImageField source="bannerImage"/>
      <TextField source="typeName"/>
      <ArrayField label="Symptoms" source="adminSymptoms">
        <Datagrid>
          <TextField label="Symptom" source="symptoms" />
        </Datagrid>
      </ArrayField>
      <BooleanField source="displayOnHt" label="Display if user is in HORMONE_THERAPY"/>
    </SimpleShowLayout>
  </ShowView>
);
