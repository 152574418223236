import {SERVER} from "../constants";
import {stringify} from "query-string";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";

export function updateMany(resource, params) {
  const query = {
    filter: JSON.stringify({id: params.objectId}),
  };
  return HttpClient(`${SERVER}/${MAP_URL[resource]}?${stringify(query)}`, {
    method: "PUT",
    body: JSON.stringify(params.data),
  }).then(({json}) => {
      return {
        data: json,
      };
    }
  );
}