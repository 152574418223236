export function fixGetManyAdmins(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      adminId: resource.objectId,
    })),
  };
}

export function fixCreateAdminUsersId(params, json) {
  if (json.phoneNumber !== undefined) {
    return {
      data: {...params.data, id: json.phoneNumber},
    };
  }
  return {
    data: {...params.result, id: json.result.phoneNumber},
  };
}

export function adminUsersMapperGetOneResponse(json) {
  return {data: {...json.result, id: json.result.phoneNumber}};
}