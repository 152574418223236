import { createMuiTheme } from "@material-ui/core/styles";

export const MyTheme = createMuiTheme ({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "rgb(162,94,173)",
      main: "rgb(104,36,117)",
      dark: "rgb(67,25,72)",
      contrastText: "rgba(244, 244, 244, 1)",
    },
    secondary: {
      light: "rgba(255, 255, 255, 1)",
      main: "rgb(81,2,105)",
      dark: "rgba(182, 194, 183, 1)",
      contrastText: "rgba(244, 244, 244, 1)",
    },
    error: {
      light: "#e57373",
      main: "rgba(217, 20, 6, 1)",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.95)",
      secondary: "rgba(0, 0, 0, 0.73)",
      disabled: "rgba(0, 0, 0, 0.42)",
      hint: "rgba(178,40,215,0.47)",
    },
  },
  typography: {
    fontFamily: "sans-serif",
    fontSize: 16,
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "black",
          fontFamily: "sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        },
      },
    },
    MuiTableCell: {
      sizeSmall: {
        textAlign: "center",
      },
    },
  },
});
