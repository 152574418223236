import {AUTH_TYPES} from "./authConstants";
import {MAP_URL} from "../constants/urls";

const {SERVER} = require("../constants");

export const AuthProvider = {
  login: ({username, password}) => {
    const req = new Request(`${SERVER}/${MAP_URL['login']}`, {
      method: "POST",
      body: JSON.stringify({phoneNumber: username, code: password}),
      headers: new Headers({"Content-Type": "application/json"}),
    });
    return fetch(req)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        const {Authorization, role} = res.result;
        localStorage.setItem(AUTH_TYPES.AUTHORIZATION, Authorization);
        localStorage.setItem(AUTH_TYPES.ROLE, role);
      });
  },
  logout: () => {
    localStorage.removeItem(AUTH_TYPES.AUTHORIZATION);
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem(AUTH_TYPES.AUTHORIZATION) ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem(AUTH_TYPES.ROLE);
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
