export const common = {
  name: "Name",
  title: "Title",
  isDeleted: "Deleted",
  updatedAt: "Updated",
  id: "ID",
  image: "Image",
  type: "Type",
};
export default {
  ra: {
    action: {
      add_filter: "Add Filter",
      add: "Add",
      back: "Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear Value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove Filter",
      remove: "Remove",
      save: "Save",
      save_and_show: "Save and Show",
      save_and_add: "Save and Add",
      back_to_list: "Back to List",
      restore: "Restore",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      unselect: "Unselect",
      expand: "Expand",
      close: "Close",
      close_menu: "Close Menu",
      open_menu: "Open Menu",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: "",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "An error occurred",
      list: "%{name} List",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} #%{id}",
      empty: "No %{name} yet.",
      invite: "Would you like to add one?",
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select them.",
        upload_single: "Drop a file to upload, or click to select it",
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select them.",
        upload_single: "Drop a picture to upload, or click to select it",
      },
      references: {
        all_missing: "Unable to find reference data.",
        many_missing: "At least one reference is no longer available.",
        single_missing: "The referenced item no longer seems to be available.",
      },
      password: {
        toggle_visible: "Hide Password",
        toggle_hidden: "Show Password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content: "Are you sure you want to delete %{name}? |||| Are you sure you want to delete %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name} items",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request could not be completed.",
      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "You either typed a wrong URL or followed a bad link.",
      yes: "Yes",
      unsaved_changes: "Your changes are not saved. Are you sure you want to discard them?",
    },
    navigation: {
      no_results: "No results found",
      no_more_results: "Page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} is out of boundaries",
      page_out_from_end: "Cannot go after the last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Previous",
      skip_nav: "Skip navigation",
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
    },
    notification: {
      updated: "Element updated",
      created: "Element created",
      deleted: "Element deleted",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "Data provider error",
      canceled: "Action canceled",
      logged_out: "Your session has ended, please reconnect.",
    },
    validation: {
      required: "Required",
      minLength: "Must be at least %{min} characters",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
      email: "Must be a valid email",
      oneOf: "Must be one of: %{options}",
      regex: "Must match the specific format (regexp): %{pattern}",
    },
  },
  Shero: {
    general: {
      generic_err: "An error occurred",
      invalid_phone_number: "Invalid phone number",
      send_code: "Verification code has been sent to your number",
    },
    menu: {
      marketing: "Marketing",
      users: "Users",
      misc: "Miscellaneous",
    },
    order: {
      success: "Order changed successfully",
      failure: "Failed to change order!",
    },
  },
  red: {
    user: {HIGH: "oio"},
  },
  resources: {
    adminUsers: {
      name: "Admins",
      fields: {
        name: "Name",
        phoneNumber: "Phone Number",
        role: "Role",
      },
    },
    advice: {
      name: "Advice",
      fields: {
        type: "Type",
        title: "Advice Title",
        text: "Content",
        urlTitle: "Call to Action Button Title",
        url: "Call to Action Link",
        bannerImage: "Banner Image",
        updatedAt: "Updated",
        isPin: "Pinned",
        version: "Version",
        isAdvertise: "Advertise",
        isHidden: "Hidden",
        expirationDate: "Expiration Date",
        createdAt: "Created",
        markets: "Markets",
        adminMarkets: "Markets",
        objectId: "ID",
        typeName: "Type",
      },
    },
    codes: {
      name: "Login Codes",
      fields: {
        phoneNumber: "Phone Number",
        code: "Login Codes",
        createdAt: "Created",
        objectId: "ID",
      },
    },
    blogCategory: {
      name: "Blog Category",
      fields: {
        icon: "Icon",
        objectId: "ID",
        title: "Title",
        order: "Order",
        markets: "Markets",
        adminMarkets: "Markets",
        updatedAt: "Updated",
        createdAt: "Created",
      },
    },
    blog: {
      name: "Blog",
      fields: {
        blogCategory: "Blog Category",
        blogCategoryId: "Blog Category ID",
        icon: "Icon",
        description: "Description",
        isPremium: "Premium",
        referenceUrl: "Reference URL",
        objectId: "ID",
        title: "Title",
        order: "Order",
        markets: "Markets",
        adminMarkets: "Markets",
        updatedAt: "Updated",
        createdAt: "Created",
      },
    },
    user: {
      name: "User",
      fields: {
        name: "Name",
        objectId: "ID",
        updatedAt: "Updated",
        phoneNumber: "Phone Number",
        email: "Email",
        registeredDate: "Registered",
        signupCompleted: "Signup Completed",
        isBlocked: "Blocked",
        weight: "Weight",
        averagePeriodLength: "Average Period Length",
        averageCycleLength: "Average Cycle Length",
        averagePredictedCycleLength: "Average Predicted Cycle Length",
        irregularityStartDay: "Irregularity Start Day",
        irregularityEndDay: "Irregularity End Day",
        averageIrregularityCycleLength: "Average Irregularity Cycle Length",
        lastPeriodStartDate: "Last Period Start Date",
        lastPeriodEndDate: "Last Period End Date",
        minimumCycleLength: "Shortest Cycle Length",
        maximumCycleLength: "Longest Cycle Length",
        firstNextPeriodStartDate: "Predicted Next Period Start Date",
        nearestPeriodOvulation: "Predicted Next Ovulation Date",
        numberOfPeriods: "Number of Recorded Periods",
        market: "Market",
        category: "Category",
        sex: "Gender",
        userGoal: "User Goal",
        deviceInfo: "Device Information",
        Role: "Role",
        description: "Description",
        avatarUrl: "Profile Picture URL",
        completed: "Completed",
        title: "Title",
        status: "Status",
        createdAt: "Created",
        birthdate: "Birthdate",
      },
    },
    config: {
      name: "Config",
      fields: {
        type: "Type",
        value: "Value",
        id: "ID",
        updatedAt: "Updated",
      },
    },
    avatar: {
      name: "Avatars",
      fields: {
        objectId: "ID",
        name: "Name",
        avatarUrl: "Avatar URL",
        active: "Active",
        createdAt: "Created",
        updatedAt: "Updated",
      },
    },
    periods: {
      name: "Period",
      fields: {
        objectId: "ID",
        createdAt: "Created",
        updatedAt: "Updated",
        startDate: "Start Date",
        endDate: "End Date",
        note: "Note",
        userId: "User ID",
      },
    },
    childWeek: {
      name: "Manage Fetal Weeks", fields: {
        weekNumber: "Week Number",
        height: "Height",
        weight: "Weight",
        description: "Description",
        additionalDescription: "Additional Description",
        imageUrl: "Image URL",
        image: "Image",
        markets: "Markets",
        adminMarkets: "Markets",
        createdAt: "Created",
        updatedAt: "Updated",
      },
    },
  },
};
