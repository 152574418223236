import * as React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import { dataProvider } from "./data/dataProvider";
import { AdminUserCreate, AdminUserEdit, AdminUserList, AdminUserShow } from "./panels/adminUsers/AdminUsers";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonIcon from "@material-ui/icons/Person";
import _LoginPage from "./components/LoginPage";
import { AuthProvider } from "./auth/authProvider";
import { MyTheme } from "./style/theme";
import { i18nProvider } from "./i18n/i18nProvider";
import MyLayout from "./components/Layout";
import Dashboard from "./panels/dashboard/Dashboard";
import { AdviceCreate, AdviceEdit, AdviceList, AdviceShow } from "./panels/advice/Advice";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import { ROLES } from "./constants";
import { MenuCategories } from "./constants/menuCategories";
import { BlogCreate, BlogEdit, BlogList, BlogShow } from "./panels/blog/Blog";
import { BlogCategoryCreate, BlogCategoryEdit, BlogCategoryList, BlogCategoryShow } from "./panels/blogCategory/BlogCategory";
import { UserShow, UserList, UserEdit } from "./panels/user/User";
import { ConfigEdit, ConfigList, ConfigShow, CreateConfig } from "./panels/config/config";
import { PeriodList } from "./panels/period/period";
import { CodeList } from "./panels/codes/code";
import {AvatarCreate, AvatarEdit, AvatarList, AvatarShow} from "./panels/avatar/Avatar";
import {ChildWeekCreate, ChildWeekEdit, ChildWeekList, ChildWeekShow} from "./panels/childWeek/ChildWeek";
import {TaskCreate, TaskEdit, TaskList, TaskShow} from './panels/task/Task'
import {
  PlanCreate,
  PlanEdit,
  PlanShow,
  PlanList
} from './panels/plan/Plan'

const App = () => (
  <Admin
    loginPage={_LoginPage}
    dataProvider={dataProvider}
    authProvider={AuthProvider}
    theme={MyTheme}
    i18nProvider={i18nProvider}
    title="Shero Admin Panel"
    layout={MyLayout}
    dashboard={Dashboard}
  >
    <Resource
      name="adminUsers"
      dataProvider={dataProvider}
      list={AdminUserList}
      create={AdminUserCreate}
      edit={AdminUserEdit}
      show={AdminUserShow}
      icon={<VerifiedUserIcon />}
      options={{ category: MenuCategories.admin, label: "Admins", permission: ROLES.SUPER_ADMIN }}
    />

    <Resource
      name="user"
      dataProvider={dataProvider}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      icon={<PersonIcon />}
      options={{ category: MenuCategories.user, label: "Users", permission: ROLES.USERS_ADMIN }}
    />

    <Resource
        name="periods"
        dataProvider={dataProvider}
        list={PeriodList}
        icon={<SpeakerNotesIcon />}
        options={{ category: MenuCategories.user, label: "Periods", permission: ROLES.USERS_ADMIN }}
    />

    <Resource
      name="advice"
      dataProvider={dataProvider}
      list={AdviceList}
      create={AdviceCreate}
      edit={AdviceEdit}
      show={AdviceShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.content, label: "Advices", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="blog"
      dataProvider={dataProvider}
      list={BlogList}
      create={BlogCreate}
      edit={BlogEdit}
      show={BlogShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.blog, label: "Blogs", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="blogCategory"
      dataProvider={dataProvider}
      list={BlogCategoryList}
      create={BlogCategoryCreate}
      edit={BlogCategoryEdit}
      show={BlogCategoryShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.blog, label: "Blog categories", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="task"
      dataProvider={dataProvider}
      list={TaskList}
      create={TaskCreate}
      edit={TaskEdit}
      show={TaskShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.task, label: "Tasks", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="plan"
      dataProvider={dataProvider}
      list={PlanList}
      create={PlanCreate}
      edit={PlanEdit}
      show={PlanShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.task, label: "Plan", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="config"
      dataProvider={dataProvider}
      list={ConfigList}
      create={CreateConfig}
      edit={ConfigEdit}
      show={ConfigShow}
      icon={<SpeakerNotesIcon />}
      options={{ category: MenuCategories.admin, label: "Config", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
        name="avatar"
        dataProvider={dataProvider}
        list={AvatarList}
        show={AvatarShow}
        create={AvatarCreate}
        edit={AvatarEdit}
        icon={<PersonIcon />}
        options={{ category: MenuCategories.user, label: "User avatars", permission: ROLES.MARKETING_ADMIN }}
    />

    <Resource
      name="codes"
      dataProvider={dataProvider}
      list={CodeList}
      icon={<PersonIcon />}
      options={{ category: MenuCategories.admin, label: "Login codes", permission: ROLES.USERS_ADMIN }}
    />

    <Resource
      name="childWeek"
      dataProvider={dataProvider}
      list={ChildWeekList}
      create={ChildWeekCreate}
      edit={ChildWeekEdit}
      show={ChildWeekShow}
      icon={<VerifiedUserIcon />}
      options={{ category: MenuCategories.content, label: "Child weeks", permission: ROLES.SUPER_ADMIN }}
    />
  </Admin>
);

export default App;
