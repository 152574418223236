export const MAP_URL = {
  login: "admin/login",
  advice: "admin/advice",
  adminUsers: "admin/adminUsers",
  user: "admin/user",
  blogCategory: "admin/blogCategory",
  blog: "admin/blog",
  plan: "admin/plan",
  task: "admin/task",
  config: "admin/config",
  periods: "admin/periods",
  codes: "admin/codes",
  sheroDeepLink : 'shero://messageNotification/',
  avatar: "admin/avatar",
  childWeek: "admin/childWeek",
};
