export function blogCategoryMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      categoryId: resource.objectId,
      adminMarkets: resource.markets.map((item) => ({markets : item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateBlogCategoryId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixGetManyBlogCategories(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      categoryId: resource.objectId,
    })),
  };
}

export function fixCreateBlogCategoryId(params, json) {
  return {
    data: {...json.result, id: json.result.objectId, categoryId: json.result.objectId},
  };
}

export function blogCategoryMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId,
      adminMarkets: json.result.markets.map((item) => ({markets : item})),},
  };
}