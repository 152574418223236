import {fetchUtils} from "react-admin";
import {AUTH_TYPES} from "../auth/authConstants";
import axios from "axios";
import {SERVER} from "../constants";

export const HttpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: "application/json"});
  }
  const token = localStorage.getItem(AUTH_TYPES.AUTHORIZATION);
  options.headers.set(AUTH_TYPES.AUTHORIZATION, `${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const SaveImage = async (resource, params) => {
  let newData = {...params.data};
  const {rawFile} = params.data.pictures;
  const formData = new FormData();
  formData.append("image", rawFile);
  formData.append("type", "general");
  const token = localStorage.getItem(AUTH_TYPES.AUTHORIZATION);

  const image = await axios.post(`${SERVER}/admin/image`, formData, {
    headers: {
      Authorization: `${token}`,
    },
  });
  newData.icon = image.data.result.url;
  newData.bannerImage = image.data.result.url;
  params.data = newData;
}

export const FileUploader = async (resource, params, files, folder = 'general') => {
  let newData = {...params.data};
  const {rawFile} = files;
  const formData = new FormData();
  formData.append("file", rawFile);
  formData.append("folder", folder);
  const token = localStorage.getItem(AUTH_TYPES.AUTHORIZATION);

  const image = await axios.post(`${SERVER}/admin/file`, formData, {
    headers: {
      Authorization: `${token}`,
    },
  });
  newData.url = image.data.result.fileUrl;
  params.data = newData;
}
