export function codesMapperListResponse(json, headers) {
  return {
    data: json.allCodes.map((resource) => ({
      ...resource,
      id: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixGetManyCodes(json) {
  return {
    data: json.allCodes.map((resource) => ({
      ...resource,
      id: resource.objectId,
    })),
  };
}