export function childWeekMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      childWeekId: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateChildWeekId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixGetManyChildWeeks(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      childWeekId: resource.objectId,
    })),
  };
}

export function fixCreateChildWeekId(params, json) {
  return {
    data: {...json.result, id: json.result.objectId, childWeekId: json.result.objectId},
  };
}

export function childWeekMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId, adminMarkets: json.result.markets.map((item) => ({markets : item}))},
  };
}