import React from "react";
import {Layout} from "react-admin";
import Menu from "./Menu.jsx";

const MyLayout = (props) => (
  <Layout
    {...props}
    menu={Menu}
  />
);

export default MyLayout;
