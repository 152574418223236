export function configMapperListResponse(json, headers) {
    return {
        data: json.map((resource) => ({
          ...resource,
          id: resource.id,
        })),
        total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
      };
}

export function fixConfigId(params, json) {
  if(json.id === undefined) {
    return {
      data: {...json.result, id: json.result.id},
    };
  }
  return {
    data: {...json.result, id: json.id},
  };
}

export function fixUpdateConfigId(json) {
  return {
    data: {...json, id: json.id},
  };
}