import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceField
} from "react-admin";

const PeriodFilter = (props) => (
    <Filter {...props}>
      <TextInput label='Phone number' source="phoneNumber" alwaysOn/>
      <TextInput label='Email' source="email" alwaysOn/>
    </Filter>
);

export const PeriodList = (props) => (
  <List {...props} filters={<PeriodFilter/>} perPage={15}>
    <Datagrid>
      <TextField source="objectId"/>
        <DateField showTime={true} source="createdAt"/>
        <DateField showTime={true} source="updatedAt"/>
        <DateField showTime={true} source="startDate"/>
        <DateField showTime={true} source="endDate"/>
        <TextField source="note"/>
        <ReferenceField link="show" label="Name" source="userId" reference="user">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="userId"/>
    </Datagrid>
  </List>
);
