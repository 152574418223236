import React from "react";
import {
  DeleteButton,
  EditButton,
  TopToolbar,
} from "react-admin";
import PropTypes from "prop-types";


ShowActions.propTypes = {
  noDel: PropTypes.bool,
};

function ShowActions({basePath, data, resource, noDel = false, noEdit = false}) {

  return (
    <TopToolbar>
      {noEdit ? null :
          (<EditButton basePath={basePath} record={data}/>
          )}
      {data?.isDeleted && !data?.text ? (
        <DeleteButton
          label={"ra.action.restore"}
          basePath={basePath}
          record={data}
          resource={resource}
          style={{color: "orange"}}
          mutationMode="pessimistic"
        />
      ) : noDel ? null : (
        <DeleteButton basePath={basePath} record={data} mutationMode="pessimistic" resource={resource}
                      undoable={false}/>
      )}
    </TopToolbar>
  );
}

export default ShowActions;
