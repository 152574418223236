import React, {useState} from "react";
import Picker from "emoji-picker-react";
import {Button} from "@material-ui/core";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import {useInput} from "react-admin";

const Emoji = (props) => {
  const {
    input: {name, onChange, ...rest},
    meta: {touched, error},
    isRequired
  } = useInput(props);
  const [text, setText] = useState(props.record.text || "");
  
  function textChange(e) {
    setText(e.target.value);
    onChange(e);
  }
  
  const onEmojiClick = (event, emojiObject) => {
    event.target.value = text + emojiObject.emoji;
    setText(event.target.value);
    textChange(event);
  };

  return (
    <div>
      <TextField
        variant="filled"
        name={name}
        multiline
        fullWidth
        label={props.label}
        onChange={textChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        {...rest}
      />
      <Button id="openPicker" style={{
        background: "rgba(0,0,0,0.09)",
        color: "silver",
        width: "15px",
        height: 48,
        borderRadius: "3px 0 0 3px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        display: "inline-flex"
      }}
              onClick={(event) => {
                document.getElementById("emojiPicker").style.display = "block"
                document.getElementById("openPicker").style.display = "none"
                document.getElementById("closePicker").style.display = "inline-flex"
              }}
              startIcon={<EmojiEmotionsIcon/>}
      >
      </Button>
      <Button id="closePicker" style={{
        background: "rgba(0,0,0,0.09)",
        color: "silver",
        width: "15px",
        height: 48,
        borderRadius: "3px 0 0 3px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        display: "none"
      }}
              onClick={(event) => {
                document.getElementById("emojiPicker").style.display = "none"
                document.getElementById("openPicker").style.display = "inline-flex"
                document.getElementById("closePicker").style.display = "none"
              }}
              startIcon={<CloseIcon/>}
      >
      </Button>
      <div id="emojiPicker" style={{display: "none"}}>
        <Picker onEmojiClick={onEmojiClick} disableAutoFocus={false}/>
      </div>
    </div>
  );
};

export default Emoji;
