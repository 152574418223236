export const Roles = [
  {id: "SUPER_ADMIN", name: "Super Admin"},
  {id: "USERS_ADMIN", name: "Normal admin"},
  {id: "MARKETING_ADMIN", name: "Marketing admin"},
];

export const ChatRoles = [
  { id: 'NORMAL', name: 'NORMAL' },
  { id: 'CRM', name: 'CRM' },
  { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN' },
];

export const Markets = [
  { id: 'GOOGLE_PLAY', name: 'GOOGLE_PLAY' },
  { id: 'IOS_APP_STORE', name: 'IOS_APP_STORE' },
  { id: 'PWA', name: 'PWA' },
];

export const MarketsFilter = [
  { name: 'GOOGLE_PLAY', value: 'GOOGLE_PLAY' },
  { name: 'IOS_APP_STORE', value: 'IOS_APP_STORE' },
  { name: 'PWA', value: 'PWA' },
];

export const Symptoms = [
  { id: 'HOT_FLASHES', name: 'Hot Flashes' },
  { id: 'NIGHT_SWEATS', name: 'Night Sweats' },
  { id: 'IRREGULAR_PERIODS', name: 'Irregular Periods' },
  { id: 'VAGINAL_DRYNESS', name: 'Vaginal Dryness' },
  { id: 'SLEEP_DISTURBANCES', name: 'Sleep Disturbances' },
  { id: 'MOOD_SWINGS', name: 'Mood Swings' },
  { id: 'FATIGUE', name: 'Fatigue' },
  { id: 'WEIGHT_GAIN', name: 'Weight Gain' },
  { id: 'LOSS_OF_LIBIDO', name: 'Loss of Libido' },
  { id: 'ANXIETY', name: 'Anxiety' },
  { id: 'IRRITABILITY', name: 'Irritability' },
  { id: 'MEMORY_ISSUES', name: 'Memory Issues' },
  { id: 'DEPRESSION', name: 'Depression' },
  { id: 'HAIR_THINNING', name: 'Hair Thinning' },
  { id: 'DRY_SKIN_AND_HAIR', name: 'Dry Skin and Hair' },
  { id: 'HEART_PALPITATIONS', name: 'Heart Palpitations' },
  { id: 'BREAST_TENDERNESS', name: 'Breast Tenderness' },
  { id: 'HEADACHES', name: 'Headaches' },
  { id: 'DIZZINESS', name: 'Dizziness' },
  { id: 'FREQUENT_URINATION', name: 'Frequent Urination' },
  { id: 'TINGLING_SENSATIONS', name: 'Tingling Sensations' },
  { id: 'VAGINAL_BLEEDING', name: 'Vaginal Bleeding' },
  { id: 'INCONTINENCE', name: 'Incontinence' },
  { id: 'BONE_LOSS', name: 'Bone Loss' },
  { id: 'MUSCLE_PAIN', name: 'Muscle Pain' },
  { id: 'NAUSEA', name: 'Nausea' },
  { id: 'HEARTBURN', name: 'Heartburn' },
  { id: 'PAIN_PASSING_URINE', name: 'Pain Passing Urine' },
  { id: 'PANIC_ATTACKS', name: 'Panic Attacks' },
  { id: 'FREQUENT_CRYING_SPELLS', name: 'Frequent Crying Spells' },
  { id: 'LACK_OF_MOTIVATION', name: 'Lack of Motivation' },
  { id: 'COLD_SWEATS', name: 'Cold Sweats' },
  { id: 'EXCITABILITY', name: 'Excitability' },
  { id: 'SKIN_CRAWLING', name: 'Skin Crawling' },
  { id: 'ITCHING', name: 'Itching' },
  { id: 'TINNITUS', name: 'Tinnitus' },
  { id: 'HAIR_LOSS_OR_THINNING', name: 'Hair Loss or Thinning' },
  { id: 'SAGGING_BREASTS', name: 'Sagging Breasts' },
  { id: 'NUMBNESS_IN_BODY', name: 'Numbness in Body' },
  { id: 'DRY_EYES', name: 'Dry Eyes' },
  { id: 'CONSTIPATION', name: 'Constipation' },
  { id: 'DIARRHEA', name: 'Diarrhea' },
  { id: 'ALTERED_SENSE_OF_SMELL', name: 'Altered Sense of Smell' },
  { id: 'ABDOMINAL_PAIN', name: 'Abdominal Pain' },
  { id: 'ACNE', name: 'Acne' },
  { id: 'FACIAL_HAIR', name: 'Facial Hair' },
  { id: 'BURNING_MOUTH', name: 'Burning Mouth' },
  { id: 'JOINT_PAIN', name: 'Joint Pain' },
  { id: 'CRAMPS', name: 'Cramps' },
  { id: 'PRESSURE_IN_HEAD', name: 'Pressure in Head' },
  { id: 'IRRITABLE_LEGS', name: 'Irritable Legs' },
  { id: 'FEELING_NERVOUS', name: 'Feeling Nervous' },
  { id: 'HORMONE_THERAPY', name: 'Hormone Therapy' },
];

export const SymptomsFilter = () => {
  return Symptoms.map((symptom) => ({
    name: symptom.name,
    value: symptom.id,
  }));
}
