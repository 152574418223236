import React, {useState} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {REACT_APP_ENVIRONMENT_MODE} from "../constants";
import packageJson from '../../package.json';
import {
  getResources,
  MenuItemLink,
} from "react-admin";
import {arrayUniqueById, hasCurrentUserPermissionByName} from "../utils/utils";
import SubMenu from "./SubMenu";
import {MenuIsOpenState} from "../constants/menuCategories";

const Menu = ({onMenuClick, logout, dense}) => {
  const [state, setState] = useState(MenuIsOpenState);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const resourcesWithPermission = [];
  resources.forEach(item => {
    if (hasCurrentUserPermissionByName(item.options.permission)) {
      resourcesWithPermission.push(item);
    }
  });

  let categories = resourcesWithPermission.map(item => item.options.category);
  categories = arrayUniqueById(categories);
  const handleIsOpenMenuToggle = (menu) => {
    setState((state) => ({...state, [menu]: !state[menu]}));
  };

  return (
    <div>
      <MenuItemLink
        key={"dashboard"}
        to="/#"
        primaryText={"Dashboard"}
        leftIcon={<DashboardIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {categories.map(category => {
        const categoryResources = resourcesWithPermission.filter(item => item.options.category.id === category.id);
        return <SubMenu
          handleToggle={() => handleIsOpenMenuToggle(category.id)}
          key={category.id}
          isOpen={state[category.id]}
          sidebarIsOpen={open}
          name={category.name}
          dense={dense}
          icon={category.icon}
        >
          {categoryResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={resource.options.label}
              leftIcon={resource.icon}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </SubMenu>;
      })}
      {logout}
      <span style={{display: 'block', margin: '10px 0px 0px 20px'}}>
        Environment: {REACT_APP_ENVIRONMENT_MODE}
      </span>
      <span style={{display: 'block', margin: '10px 0px 0px 20px'}}>
        Version: {packageJson.version}
      </span>
    </div>
  );
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

export default Menu;
