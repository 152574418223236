import * as React from "react";
import {Card, CardHeader} from '@material-ui/core';

const Dashboard = () => (
  <Card>
    <CardHeader title="Welcome to Shero Admin Panel"/>
  </Card>
);

export default Dashboard;
