export const AdviceTypes = [
  {id: 1, name:  "First time opened app"},
  {id: 2, name: "General"},
  {id: 3, name: "Period"},
  {id: 4, name: "Luteal"},
  {id: 5, name: "Follicular"},
  {id: 6, name: "Ovulation"},
  {id: 7, name: "Partner"},
  {id: 10, name: "Pregnancy"},
  {id: 11, name: "Menopause"},
];

export const AdviceFilterTypes = [
  {name: "First time opened app", value: 1},
  {name: "General", value: 2},
  {name: "Period", value: 3},
  {name: "Luteal", value: 4},
  {name: "Follicular", value: 5},
  {name: "Ovulation", value: 6},
  {name: "Partner", value: 7},
  {name: "Pregnancy", value: 10},
  {name: "Menopause", value: 11},
];

export const AdviceFilterConditions = [
  {name: "Yes", value: true},
  {name: "No", value: false}
];
