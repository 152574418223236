import React, {useState} from "react";
import {Notification, useLogin, useNotify} from "react-admin";
import {ThemeProvider} from "@material-ui/styles";
import {Avatar, Card} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import {SERVER_DOMAIN} from "../constants";
import bg from "../assets/bg.jpg";
import {PhoneValidate} from "../utils/validation";

const _LoginPage = ({ theme }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Enter password");
  const [setInvalidPhone] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    if (PhoneValidate(phoneNumber).valid) {
      setLoading(true);
      if (btnText === "Enter password") {
        axios
          .post(`${SERVER_DOMAIN}/requestCode`, {
            phoneNumber: phoneNumber,
          })
          .then((res) => {
            if (res.status < 200 || res.status >= 300) {
              throw new Error(res.statusText);
            } else {
              setBtnText("Confirm and enter");
              setLoading(false);
              notify("Shero.general.send_code", "info");
            }
          })
          .catch((err) => {
            setLoading(false);
            notify("Shero.general.generic_err", "warning");
          });
      } else {
        login({ username: phoneNumber, password: code }).catch(() => {
          setLoading(false);
          notify("ra.auth.sign_in_error", "warning");
        });
      }
    } else {
      setInvalidPhone(true);
      notify("Shero.general.invalid_phone_number", "warning");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: `url(${bg}), linear-gradient(to top, #37ecba 0%, #72afd3 100%)`,
        }}
      >
        <Card
          style={{
            minWidth: 400,
            margin: "8em",
            padding: "1em 1em 0 1em",
            minHeight: 400,
            background: "rgba( 255, 255, 255, 0.4 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 4.0px )",
            WebkitBackdropFilter: "blur( 4.0px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
          }}
          elevation={5}
        >
          <div
            style={{
              margin: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                backgroundColor: theme.palette.secondary[500],
                width: 80,
                height: 80,
                marginTop: 10,
              }}
              src="Shero.png"
            />
          </div>
          <Typography
            style={{ textAlign: "center", marginBottom: "0.8em" }}
            variant="h6"
          >
            Shero
          </Typography>
          <form
            onSubmit={submit}
            style={{
              padding: "0 1em 1em 1em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              disabled={btnText === "Verification and enter"}
              style={{ margin: "0 0 0.5em 0" }}
              name="phone"
              label="Phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="outlined"
            />
            <TextField
              style={{ margin: "0.5em 0" }}
              name="code"
              label="Verification code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              variant="outlined"
              disabled={btnText === "Enter password"}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              style={{ margin: "1em 0", padding: "0.8em 0" }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                btnText
              )}
            </Button>
          </form>
        </Card>
      </div>
      {/* </div> */}
      <Notification />
    </ThemeProvider>
  );
};

export default _LoginPage;
