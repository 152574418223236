import {getList} from "./GetList";
import {getOne} from "./GetOne";
import {getMany} from "./getMany";
import {getManyReference} from "./getManyReference";
import {update} from "./update";
import {updateMany} from "./updateMany";
import {deleteOne} from "./delete";
import {deleteMany} from "./deleteMany";
import {createOne} from "./createOne";

export const dataProvider = {
  getList: (resource, params) => getList(resource, params),
  getOne: (resource, params) => getOne(resource, params),
  getMany: (resource, params) => getMany(resource, params),
  getManyReference: (resource, params) => getManyReference(resource, params),
  update: (resource, params) => update(resource, params),
  updateMany: (resource, params) => updateMany(resource, params),
  create: (resource, params) => createOne(resource, params),
  delete: (resource, params) => deleteOne(resource, params),
  deleteMany: (resource, params) => deleteMany(resource, params),
};