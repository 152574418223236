import React from "react";
import {
  Datagrid,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  DateField,
  SelectInput,
  required
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import { ConfigTypes } from "./configConstants";

export const ConfigList = (props) => (
    <List {...props} perPage={10}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <DateField showTime={true} source="updatedAt"/>
        <TextField showTime={true} source="type"/>
        <TextField source="value"/>
      </Datagrid>
    </List>
);

export const CreateConfig = (props) => (
  <CreateView {...props}>
    <SelectInput source="type" validate={required()} choices={ConfigTypes}/>
    <TextInput source="value"/>
  </CreateView>
);

export const ConfigEdit = (props) => (
  <EditView {...props} noDel={true}>
    <SelectInput source="type" validate={required()} choices={ConfigTypes}/>
    <TextInput source="value"/>
  </EditView>
);

export const ConfigShow = (props) => (
  <ShowView {...props} noDel= {true}>
    <SimpleShowLayout>
      <TextField source="id"/>
      <DateField showTime={true} source="updatedAt"/>
      <TextField source="type"/>
      <TextField source="value"/>
    </SimpleShowLayout>
  </ShowView>
);
