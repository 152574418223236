export function userMapperListResponse(json, headers) {
  return {
    data: json.allUser.map((resource) => ({
      ...resource,
      id: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixGetManyUsers(json) {
  return {
    data: json.allUser.map((resource) => ({
      ...resource,
      id: resource.objectId,
    })),
  };
}

export function userMapperGetOneResponse(params, json) {
  return {
    data: {
      ...json.result.user,
      id: json.result.user.objectId,
    },
  };
}
