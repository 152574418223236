import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  Filter,
  TextInput,
} from "react-admin";

const CodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Phone number" source="phoneNumber"/>
  </Filter>
);

export const CodeList = (props) => (
  <List {...props} filters={<CodeFilter/>} perPage={15}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <TextField source="phoneNumber"/>
      <TextField source="code"/>
      <DateField showTime={true} source="createdAt"/>
    </Datagrid>
  </List>
);
