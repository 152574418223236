import {SERVER} from "../constants";
import {FileUploader, HttpClient} from "../utils/requests";
import {MAP_URL} from "../constants/urls";
import {fixCreateAdviceId} from "../panels/advice/adviceMapper";
import {fixCreateBlogCategoryId} from "../panels/blogCategory/blogCategoryMapper";
import {fixCreateBlogId} from "../panels/blog/blogMapper";
import {fixCreateAdminUsersId} from "../panels/adminUsers/adminUsersMapper";
import {fixConfigId} from "../panels/config/configMapper";
import {fixCreateAvatarId} from "../panels/avatar/avatarMapper";
import {fixCreateChildWeekId} from "../panels/childWeek/childWeekMapper";
import {fixCreatePlanId} from '../panels/plan/planMapper'
import {fixCreateTaskId} from '../panels/task/taskMapper'

export async function createOne(resource, params) {
  if (resource === 'advice' && params.data.pictures) {
    await FileUploader(resource, params, params.data.pictures, 'advice');
    params.data['bannerImage'] = params.data.url;
    delete params.data.url;
    delete params.data.pictures;
  }

  if (['blogCategory', 'blog', 'task', 'plan'].includes(resource) && params.data.pictures) {
    await FileUploader(resource, params, params.data.pictures, resource);
    params.data['icon'] = params.data.url;
    delete params.data.url;
    delete params.data.pictures;
  }

  if (resource === 'avatar' && params.data.avatar) {
    await FileUploader(resource, params, params.data.avatar, 'admin_avatar');
    params.data['avatarUrl'] = params.data.url;
    delete params.data.url;
    delete params.data.avatar;
  }

  if (resource === 'childWeek' && params.data.image) {
    await FileUploader(resource, params, params.data.image);
    params.data['imageUrl'] = params.data.url;
    delete params.data.url;
    delete params.data.image;
  }

  if (resource === 'advice') {
    params.data.expirationDate = params.data.expirationDate && new Date(params.data.expirationDate).getTime();
  }

  if (['blog', 'blogCategory', 'childWeek'].includes(resource)) {
    params.data.markets = params.data.adminMarkets?.map(item => item.markets);
  }

  if (['plan', 'advice'].includes(resource)) {
    params.data.symptoms = params.data.adminSymptoms?.map(item => item.symptoms);
  }

  return HttpClient(`${SERVER}/${MAP_URL[resource]}`, {
    method: "POST",
    body: JSON.stringify(params.data),
  }).then(({json}) => {
      if (resource === 'advice') {
        return fixCreateAdviceId(params, json);
      } else if (resource === 'blogCategory') {
        return fixCreateBlogCategoryId(params, json);
      } else if (resource === 'blog') {
        return fixCreateBlogId(params, json);
      } else if (resource === 'plan') {
        return fixCreatePlanId(params, json);
      } else if (resource === 'task') {
        return fixCreateTaskId(params, json);
      } else if (resource === 'adminUsers') {
        return fixCreateAdminUsersId(params, json);
      } else if (resource === 'config') {
        return fixConfigId(params, json);
      } else if (resource === 'avatar') {
        return fixCreateAvatarId(params, json);
      } else if (resource === 'childWeek') {
        return fixCreateChildWeekId(params, json);
      }

      return {
        data: {...params.data, id: json.objectId},
      };
    }
  );
}
