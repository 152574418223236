export function avatarMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      avatarId: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateAvatarId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixGetManyAvatars(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      avatarId: resource.objectId,
    })),
  };
}

export function fixCreateAvatarId(params, json) {
  return {
    data: {...json.result, id: json.result.objectId, avatarId: json.result.objectId},
  };
}

export function avatarMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}