export function blogMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      blogId: resource.objectId,
      adminMarkets: resource.markets.map((item) => ({markets : item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateBlogId(json) {
  return {
    data: {...json, id: json.objectId},
  };
}

export function fixCreateBlogId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function blogMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId, blogId: json.result.objectId,
      adminMarkets: json.result.markets.map((item) => ({markets : item})),},
  };
}