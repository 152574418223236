import React from "react";
import {
  Datagrid,
  List, NumberInput,
  ImageField,
  ImageInput,
  SimpleShowLayout,
  TextField,
  TextInput, DateField,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  Filter
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import { Markets, MarketsFilter } from "../../constants/choices";

const BlogCategoryFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Market"
      choices={MarketsFilter}
      optionText={"name"}
      optionValue={"value"}
      source={"markets"}
      alwaysOn
    />
  </Filter>
);

export const BlogCategoryList = (props) => (
  <List {...props} filters={<BlogCategoryFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ImageField source="icon"/>
      <TextField source="title"/>
      <TextField source="order"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets" />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export const BlogCategoryEdit = (props) => (
  <EditView {...props}>
    <TextInput source="title"/>
    <NumberInput source="order"/>
    <ImageInput source="pictures" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <TextInput source="icon"/>
    <ArrayInput source="adminMarkets">
      <SimpleFormIterator>
        <SelectInput source="markets" choices={Markets} />
      </SimpleFormIterator>
    </ArrayInput>
  </EditView>
);

export const BlogCategoryShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ImageField source="icon"/>
      <TextField source="title"/>
      <TextField source="order"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </ShowView>
);

export const BlogCategoryCreate = (props) => (
  <CreateView {...props}>
    <TextInput source="title"/>
    <NumberInput source="order"/>
    <ImageInput source="pictures" label="Image" accept="image/*">
      <ImageField source="src" title="imageUrl"/>
    </ImageInput>
    <ArrayInput source="adminMarkets">
      <SimpleFormIterator>
        <SelectInput source="markets" choices={Markets} />
      </SimpleFormIterator>
    </ArrayInput>
  </CreateView>
);
