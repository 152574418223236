import {SERVER} from "../constants";
import {HttpClient} from "../utils/requests";
import {MAP_URL} from "../constants/urls";

export function deleteOne(resource, params) {
  return HttpClient(`${SERVER}/${MAP_URL[resource]}/${params.id}`, {
    method: 'DELETE',
  }).then(({json}) => {
    return {
      data: json
    };
  });
}
