import React from "react";
import ShowActions from "./ShowActions";
import {Show} from "react-admin";
import PropTypes from "prop-types";

ShowView.propTypes = {
  noDel: PropTypes.bool,
  noEdit: PropTypes.bool,
};

function ShowView(props) {
  const {noDel = false} = props;
  const {noEdit = false} = props;

  return (
    <Show {...props} actions={<ShowActions noDel={noDel} noEdit={noEdit}/>}>
      {props.children}
    </Show>
  );
}

export default ShowView;
